<ng-container *ngIf="orderView?.data as order">
  <x-action-row>
    <span xActionStart class="mat-subtitle-2">
      <fa-icon icon="stroopwafel"></fa-icon>
      @if (canUpdateItems && updateItemsAllowedOrderStates.includes(order.state)) {
        Add items
      } @else {
        Order items
      }
    </span>

    @if (canUpdateItems && updateItemsAllowedOrderStates.includes(order.state)) {
      <x-search-field xActionFlex>
        <x-data-autocomplete
          #autocomplete
          class="product-variant-autocomplete"
          xToolbarAction
          placeholder="Search for a product or variant to add..."
          [formControl]="productVariantControl"
          [provider]="Providers.OrderDetailProductVariantItemCollectionProvider"
          [filter]="{ channelIds: [order.channel.id], publishStates: ['PUBLISHED'] }"
          [args]="{
            channelCode: order.channel.code,
          }"
        >
          <ng-container *xDataAutocompleteOptionDef="let option">
            <div class="product-variant-autocomplete-item">
              <span class="text">
                <span class="name">{{ option.display }}</span>
                <span class="sku">{{ option.item.stockItem.sku }}</span>
              </span>
              <span class="icons">
                @if (variantProductHidden(option.item)) {
                  <fa-icon icon="eye-slash" matTooltip="Product is hidden"></fa-icon>
                }
                @if (variantUnavailableInShippingAddressGeoRegion(option.item)) {
                  <fa-icon
                    icon="map-marker-alt-slash"
                    matTooltip="Not available for shipping address"
                  ></fa-icon>
                }
                @if (variantUnavailableForPurchase(option.item)) {
                  <fa-icon
                    xColor="warn"
                    icon="shop-slash"
                    matTooltip="Variant not available for purchase"
                  ></fa-icon>
                }
              </span>
              <span class="price">
                {{ option.item.channel?.price ?? 0 | money: order.currency }}
              </span>
            </div>
          </ng-container>
        </x-data-autocomplete>
      </x-search-field>
    }

    <button mat-icon-button xActionEnd [matMenuTriggerFor]="addItemsMenu" matTooltip="More Actions">
      <fa-icon icon="ellipsis-v"></fa-icon>
    </button>

    <mat-menu #addItemsMenu>
      <button
        mat-menu-item
        [disabled]="!canAddShippingFee || !order.cartShipment?.method?.costProductVariant?.id"
        (click)="setShippingFee.emit()"
      >
        <fa-icon icon="truck-fast"></fa-icon>
        Set Shipping Fee
      </button>
    </mat-menu>
  </x-action-row>

  <mat-divider></mat-divider>

  <div *ngIf="order.items.length; else addItems" class="order-items-table-container">
    <table mat-table [dataSource]="order.items" class="order-items-table">
      <ng-container matColumnDef="alert" class="d-flex">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let orderItem" valign="center">
          <div style="display: flex; flex-flow: row wrap; gap: 0.4rem">
            @if (!canBeFulfilled(orderItem)) {
              <fa-icon
                icon="circle-exclamation"
                matTooltip="Insufficient stock assigned"
                xColor="warn"
              ></fa-icon>
            }
            @if (variantUnavailableInShippingAddressGeoRegion(orderItem.variant)) {
              <fa-icon
                icon="map-marker-alt-slash"
                matTooltip="Not available for shipping address"
                xColor="warn"
              ></fa-icon>
            }
            @if (variantProductHidden(orderItem.variant)) {
              <fa-icon icon="eye-slash" matTooltip="Product is hidden" xColor="accent"></fa-icon>
            }
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let orderItem">
          <span class="mat-subtitle-2" matTooltip="Order item id">{{ orderItem.id }}</span>
          <br />
          <span class="mat-hint" matTooltip="Order item source">
            {{ 'OrderItemSource.' + orderItem.source | l }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef>Product</th>
        <td mat-cell *matCellDef="let orderItem">
          <a
            class="product-name"
            [routerLink]="['/admin/products/detail', orderItem.variant.product.id]"
            [matTooltip]="
              orderItem.productName + (orderItem.variantName ? ' - ' + orderItem.variantName : '')
            "
          >
            <span class="mat-subtitle-2">
              {{ orderItem.productName }}
            </span>
            <span *ngIf="orderItem.variantName" class="mat-hint">
              - {{ orderItem.variantName }}
            </span>
          </a>
          @if (orderItem.variant.stockItem) {
            <a
              class="mat-hint"
              [routerLink]="['/admin/inventory/stock-items/detail', orderItem.variant.stockItem.id]"
            >
              {{ orderItem.variant.stockItem.sku }}
            </a>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let orderItem" class="mat-subtitle-2">
          @if (
            updateItemsAllowedOrderStates.includes(order.state) &&
            orderItem.source !== 'SHIPPING' &&
            canUpdateItems
          ) {
            <x-cart-quantity-control
              [disabled]="!canModifyItemQuantityPredicate(orderItem)"
              [quantity]="orderItem.quantity"
              (quantityUpdated)="quantityUpdated(orderItem, $event)"
            ></x-cart-quantity-control>
          } @else {
            <span class="mat-subtitle-2">
              {{ orderItem.quantity }}
            </span>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="unitsTotal">
        <th mat-header-cell *matHeaderCellDef>Unit(s) Total</th>
        <td mat-cell *matCellDef="let orderItem" class="mat-body-1">
          {{ orderItem.unitsTotal | money: order.currency }}
          <br />
          <span class="mat-body-2">{{ orderItem.unitPrice | money: order.currency }}/ea.</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="taxTotal">
        <th mat-header-cell *matHeaderCellDef>Tax</th>
        <td mat-cell *matCellDef="let orderItem" class="mat-body-1">
          {{ orderItem.taxAmount | money: order.currency }}
          <br />
          <span class="mat-body-2">{{ orderItem.taxRate / 10000 | number: '1.0-3' }}%</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="adjustmentsTotal">
        <th mat-header-cell *matHeaderCellDef>Adjustment</th>
        <td mat-cell *matCellDef="let orderItem" class="mat-body-2">
          @if (orderItem.adjustmentsTotal !== 0) {
            {{ orderItem.adjustmentsTotal | money: order.currency }}
          } @else {
            -
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Item Total</th>
        <td mat-cell *matCellDef="let orderItem" class="mat-body-1">
          {{ orderItem.total | money: order.currency }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let orderItem" class="actions-col">
          @if (adjustItemsAllowedOrderStates.includes(order.state)) {
            <button
              mat-icon-button
              (click)="adjustItem(orderItem)"
              matTooltip="Adjust Item"
              [disabled]="!canAdjustItemPredicate(orderItem) || orderItem.source === 'SHIPPING'"
            >
              <fa-icon icon="plus-minus"></fa-icon>
            </button>
          }
          @if (removeItemsAllowedOrderStates.includes(order.state)) {
            <button
              mat-icon-button
              (click)="removeItem(orderItem)"
              matTooltip="Remove Item"
              [disabled]="!canRemoveItemPredicate(orderItem) || orderItem.source === 'SHIPPING'"
            >
              <fa-icon icon="trash"></fa-icon>
            </button>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="orderTableColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: orderTableColumns"
        [class]="'source-' + row.source"
      ></tr>
    </table>
  </div>

  <ng-template #addItems>
    <div class="px-4 py-5 d-flex flex-column gap-1 align-items-center justify-content-center">
      <i>
        <fa-icon icon="info-circle"></fa-icon>
        Add items using the search above
      </i>
    </div>
  </ng-template>
</ng-container>
