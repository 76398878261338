import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataView } from '@x/common/data';
import { Operation, OperationCancelledState } from '@x/common/operation';
import { ShipmentActionsService } from '@x/ecommerce-admin/app/logistics/services/shipment-actions.service';
import { ShipmentDialogService } from '@x/ecommerce-admin/app/logistics/services/shipment-dialog.service';
import {
  IOrderIncidentDetailObject,
  OrderIncidentService,
  ShipmentObject,
  ShipmentService,
} from '@x/ecommerce/domain-client';
import { ShipmentStateTransition } from '@x/schemas/ecommerce';
import { firstValueFrom, lastValueFrom, switchMap } from 'rxjs';
import { OrderDialogService } from '../../services/order-dialog.service';
import { OrderIncidentActionsService } from '../../services/order-incident-actions.service';

export type InfoPanelDisplayMode = 'visible' | 'hidden';
export type ModifiableInfoPanelDisplayMode = 'readonly' | InfoPanelDisplayMode;

@Component({
  selector: 'x-order-incident-info-panel',
  templateUrl: './order-incident-info-panel.component.html',
  styles: `
    :host {
      display: flex;
      flex-flow: column nowrap !important;
      align-items: stretch !important;
    }
  `,
})
export class OrderIncidentInfoPanelComponent implements OnInit, OnDestroy {
  @Input() view: DataView<IOrderIncidentDetailObject, number>;

  @Input() showIncidentMenu = true;
  @Input() incidentDetails: InfoPanelDisplayMode = 'visible';
  @Input() incidentError: InfoPanelDisplayMode = 'hidden';
  @Input() approverInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() originalOrderInfo: InfoPanelDisplayMode = 'hidden';
  @Input() returnShipmentInfo: InfoPanelDisplayMode = 'hidden';
  @Input() replacementOrderInfo: InfoPanelDisplayMode = 'hidden';

  trackById = (i: number, item: { id: number }) => item.id;

  constructor(
    private readonly snackbar: MatSnackBar,
    public readonly orderIncidentActions: OrderIncidentActionsService,
    private readonly shipmentActions: ShipmentActionsService,
    private readonly shipmentDialogService: ShipmentDialogService,
    private readonly shipmentService: ShipmentService,
    private readonly incidentService: OrderIncidentService,
    private readonly orderDialogService: OrderDialogService,
  ) {}

  ngOnInit(): void {
    this.view.connect();
  }
  ngOnDestroy(): void {
    this.view.disconnect();
  }

  reconShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.reconShipment({
          id,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Reconciled'));
  }
  submitShipmentWaybill(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Ready,
        }),
      )
      .subscribe((operation) =>
        this.handleOperationResult(operation, 'Shipment Waybill Submitted'),
      );
  }
  shipShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Ship,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Shipped'));
  }
  deliverShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Deliver,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Delivered'));
  }
  cancelShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Cancel,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Cancelled'));
  }
  failShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Fail,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Failed'));
  }

  async rescheduleShipment(shipmentId: number) {
    const result = await lastValueFrom(
      this.shipmentDialogService
        .openShipmentFormDialog({
          shipmentId,
        })
        .afterClosed(),
    );

    if (!result) return;

    const { methodId, slotId } = result;

    this.view
      .observeSubMutation(shipmentId, (id) =>
        this.shipmentService.rescheduleShipment({
          id: shipmentId,
          methodId,
          slotId,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Rescheduled'));
  }

  async recreateShipment(shipmentId: number) {
    const incidentId = this.view.id;

    if (!incidentId) return;

    const result = await lastValueFrom(
      this.shipmentDialogService
        .openShipmentFormDialog({
          shipmentId,
        })
        .afterClosed(),
    );

    if (!result) return;

    const { methodId, slotId } = result;

    this.view
      .observeSubMutation(shipmentId, () =>
        this.shipmentService
          .recreateShipment({
            id: shipmentId,
            methodId,
            slotId,
          })
          .pipe(switchMap(({ id }) => this.incidentService.assignReturnShipment(incidentId, id))),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Recreated'));
  }

  async openParcelDialog(shipmentId: number) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderCreateParcelDialog().afterClosed(),
    );

    if (!result) return;

    this.view
      .observeSubMutation(shipmentId, (id) =>
        this.shipmentService.createShipmentParcel({ shipmentId: Number(id), ...result }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel created'));
  }
  async removeParcel(parcel: any) {
    const result = await firstValueFrom(
      this.orderDialogService.openParcelRemoveConfirmation(parcel),
    );

    if (!result) return;

    this.view
      .observeMutation(() => this.shipmentService.removeParcel(parcel.id))
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel removed'));
  }

  async editParcel(parcel: any) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderUpdateParcelDialog(parcel).afterClosed(),
    );
    if (!result) return;

    this.view
      .observeMutation(() => this.shipmentService.updateParcel({ id: parcel.id, ...result }))
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel updated'));
  }

  async editReturnShipment(shipment: ShipmentObject) {
    if (!this.view.data) return;
    this.view
      .observeMutation(() => this.shipmentActions.editReturnShipment(shipment))
      .subscribe((operation) => this.handleOperationResult(operation, 'Return Shipment Updated'));
  }

  private handleOperationResult(operation: Operation, successMessage?: string) {
    if (operation instanceof OperationCancelledState) {
      return;
    }
    if (operation.isSuccessState() && successMessage) {
      this.snackbar.open(successMessage);
    }
    if (operation.isErrorState()) {
      this.snackbar.open(operation.state.error.message);
    }
  }
}
