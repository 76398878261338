<button
  id="sync-bounds-button"
  mat-flat-button
  *ngIf="boundsUnsynced$ | async"
  (click)="commitBoundsChanges()"
>
  Search this area
</button>

<x-dashboard-panel [panelLoading]="view.fetchLoading" [panelError]="view.fetchError">
  <x-map
    [markers]="markers$ | async"
    [polygons]="polygons$ | async"
    [mapOptions]="mapOptions"
    [clusterOptions]="{
      minimumClusterSize: 5,
    }"
    [infoWindowTemplate]="infoWindow"
    [infoWindowOptions]="{
      maxWidth: 700,
    }"
    (boundsChanged)="mapBoundsChanged($event)"
    (centerChanged)="mapCenterChanged($event)"
    (zoomChanged)="mapZoomChanged($event)"
  ></x-map>

  <x-data-table-controls
    xPanelFooter
    [view]="view"
    [pagination]="false"
    [sort]="false"
    [columns]="false"
  >
    <div class="map-table-controls-container">
      <ng-container *ngIf="view.data() | async as data">
        <mat-slide-toggle [formControl]="showGeoRegionsControl">
          Show Geo Region Boundaries
        </mat-slide-toggle>

        <span *ngIf="view.fetchLoading">Loading...</span>
        <span *ngIf="!view.fetchLoading">
          Showing {{ data.items.length }} / {{ data.totalItemsCount }} orders
        </span>
        <!-- <button
          mat-icon-button
          type="button"
          [disabled]="!view.fetchLoading"
          [matTooltip]="'View these orders in table mode'"
        >
          <fa-icon icon="filter-list"></fa-icon>
        </button> -->
      </ng-container>
    </div>
  </x-data-table-controls>
</x-dashboard-panel>

<ng-template #infoWindow let-data>
  <div class="info-window-inner">
    <ng-container *ngFor="let order of data">
      <div class="order-info-window-row">
        <x-order-pill [order]="order"></x-order-pill>
        <x-address-pill [address]="order.shippingAddress"></x-address-pill>
        <x-shipment-pill [shipment]="order.shipment"></x-shipment-pill>
        <button
          mat-icon-button
          [matMenuTriggerFor]="mapItemMenu"
          [matMenuTriggerData]="{ $implicit: order }"
          class="ms-0"
        >
          <fa-icon icon="ellipsis-v"></fa-icon>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<mat-menu #mapItemMenu="matMenu">
  <ng-template matMenuContent let-order>
    <button mat-menu-item [routerLink]="['/admin/orders', order.id, 'detail']">View</button>
    <button
      mat-menu-item
      [disabled]="!['CART', 'QUOTED', 'CONFIRMED', 'PROCESSING', 'ATTENTION'].includes(order.state)"
      (click)="openAddressFormDialog(order.id, order.shippingAddress)"
    >
      Update Shipping Address
    </button>
  </ng-template>
</mat-menu>
