<ng-container *ngIf="view.data as incident">
  <!-- Incident Details -->

  @if (incidentDetails !== 'hidden') {
    <xd-list-item icon="circle-exclamation-check">
      <span xdHeadline>
        <span>Order Incident</span>
      </span>
      <span xdLine>ID: {{ incident.id }}</span>
      <span xdLine>
        Created By:
        <b>
          <b>{{ incident.authorUser.firstName }} {{ incident.authorUser.lastName }}</b>
        </b>
      </span>
      <span xdLine *ngIf="incident.approvalState === 'APPROVED' && incident.approverUser">
        <span xdLine>
          Approved by:
          <b>{{ incident.approverUser.firstName }} {{ incident.approverUser.lastName }}</b>
        </span>
      </span>
    </xd-list-item>

    @if (incidentError !== 'hidden') {
      <xd-expand-item
        class="warning"
        icon="triangle-exclamation"
        *ngIf="view.data.errorDescription as errorDescription"
      >
        <span xdHeadline>
          <span>Error</span>
        </span>
        <p>{{ errorDescription }}</p>
      </xd-expand-item>
    }

    <xd-list-item icon="swatchbook">
      <span xdHeadline>
        <span>Category</span>
      </span>
      <span xdLine>
        {{ incident.category?.name }}
        <fa-icon *ngIf="incident.subcategory" icon="chevron-right"></fa-icon>
        {{ incident.subcategory?.name }}
      </span>
    </xd-list-item>

    <xd-list-item icon="signature">
      <span xdHeadline>
        <span>Description</span>
      </span>
      <span xdLine preserveWhitespace>
        {{ incident.description }}
      </span>
    </xd-list-item>
  }

  <!-- Approver Info -->
  @if (approverInfo !== 'hidden' && incident.approvalState !== 'APPROVED') {
    <xd-list-item icon="user">
      <span xdHeadline>Approver</span>
      @if (incident.approverUser; as user) {
        <span xdLine>{{ user.firstName }} {{ user.lastName }}</span>
      }

      <span xdLine *ngIf="incident.approverUser as user">{{ user.email }}</span>

      <span xdLine *ngIf="incident.approverUser === null || incident.approverUser === undefined">
        No Approver Assigned
      </span>

      @if (approverInfo !== 'readonly' && ['DRAFT', 'PENDING'].includes(incident.approvalState)) {
        <button
          mat-icon-button
          color="warn"
          (click)="orderIncidentActions.unassignApprover(view)"
          matTooltip="Remove Approver"
        >
          <fa-icon icon="trash"></fa-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="orderIncidentActions.assignApprover(view)"
          matTooltip="Assign Approver"
          class="me-2"
        >
          <fa-icon icon="edit"></fa-icon>
        </button>
      }
    </xd-list-item>
  }

  <!-- Original Order Info -->
  @if (originalOrderInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="clipboard-list" *ngIf="view.data?.order as order">
      <span xdHeadline>Original Order</span>
      <span xdLine *ngIf="order.number">
        <span [xClip]="order.number">Order #{{ order.number }}</span>
      </span>

      <x-order-totals xdBody *ngIf="incident.order" [order]="incident.order"></x-order-totals>

      <xd-list-item icon="store">
        <span xdHeadline>Channel</span>
        <span xdLine>{{ incident.order.channel.name }}</span>
        <span xdLine>{{ incident.order.locale }}</span>
      </xd-list-item>

      <xd-list-item icon="user" *ngIf="view.data?.order?.user as user">
        <span xdHeadline>Customer</span>
        <span xdLine>{{ user.firstName }} {{ user.lastName }}</span>
        <span xdLine>{{ user.email }}</span>
        <span xdLine *ngIf="user.phone">{{ user.phone }}</span>
        <button mat-icon-button [matMenuTriggerFor]="orderUserMenu">
          <fa-icon icon="ellipsis-v"></fa-icon>
        </button>
        <mat-menu #orderUserMenu="matMenu">
          <ng-template matMenuContent>
            <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
          </ng-template>
        </mat-menu>
      </xd-list-item>

      @if (incident.order.items.length > 0) {
        <xd-expand-item icon="stroopwafel">
          <span xdHeadline>Order Items</span>
          <table class="mat-typography product-summary-table">
            <tbody>
              <tr *ngFor="let item of incident.order.items">
                <td style="text-align: left">
                  <a
                    [routerLink]="[
                      '/admin/inventory/stock-items/detail',
                      item.variant.stockItem?.id,
                    ]"
                  >
                    {{ item.variant.stockItem?.sku }}
                  </a>
                </td>
                <td style="text-align: left">
                  {{ item.productName }}
                </td>
                <td>{{ item.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </xd-expand-item>
      }

      <x-action-row>
        <a mat-flat-button color="accent" [routerLink]="['/admin/orders', order.id, 'detail']">
          View Order
        </a>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Return Shipment Info -->
  @if (returnShipmentInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="truck" *ngIf="view.data?.returnShipment as returnShipment">
      <span xdHeadline>Return Shipment</span>

      <x-shipment-info-box
        [shipment]="returnShipment"
        (viewParcel)="openParcelDialog($event)"
        (removeParcel)="removeParcel($event)"
        (editParcel)="editParcel($event)"
        (recon)="reconShipment($event)"
        (submitWaybill)="submitShipmentWaybill($event)"
        (ship)="shipShipment($event)"
        (deliver)="deliverShipment($event)"
        (cancel)="cancelShipment($event)"
        (fail)="failShipment($event)"
        (reschedule)="rescheduleShipment($event)"
        (recreate)="recreateShipment($event)"
        (editReturn)="editReturnShipment($event)"
        editLabel="Assign Slot"
      ></x-shipment-info-box>
    </xd-expand-item>
  }

  <!-- Replacement Order Info -->
  @if (replacementOrderInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item
      icon="clipboard-list"
      *ngIf="view.data?.replacementOrder as order"
      [isExpanded]="true"
    >
      <span xdHeadline>Replacement Order</span>
      <span xdLine *ngIf="order.number">
        <span [xClip]="order.number">Order #{{ order.number }}</span>
      </span>

      <x-order-totals xdBody *ngIf="order" [order]="order"></x-order-totals>
      <xd-list-item icon="user" *ngIf="view.data?.replacementOrder?.user as user">
        <span xdHeadline>Customer</span>
        <span xdLine>{{ user.firstName }} {{ user.lastName }}</span>
        <span xdLine>{{ user.email }}</span>
        <span xdLine *ngIf="user.phone">{{ user.phone }}</span>
        <button mat-icon-button [matMenuTriggerFor]="orderUserMenu">
          <fa-icon icon="ellipsis-v"></fa-icon>
        </button>
        <mat-menu #orderUserMenu="matMenu">
          <ng-template matMenuContent>
            <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
          </ng-template>
        </mat-menu>
      </xd-list-item>

      @if (order.items.length > 0) {
        <xd-expand-item icon="stroopwafel">
          <span xdHeadline>Order Items</span>
          <table class="mat-typography product-summary-table">
            <tbody>
              <tr *ngFor="let item of order.items">
                <td style="text-align: left">
                  <a
                    [routerLink]="[
                      '/admin/inventory/stock-items/detail',
                      item.variant.stockItem?.id,
                    ]"
                  >
                    {{ item.variant.stockItem?.sku }}
                  </a>
                </td>
                <td style="text-align: left">
                  {{ item.productName }}
                </td>
                <td>{{ item.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </xd-expand-item>
      }

      <x-action-row>
        <a mat-flat-button color="accent" [routerLink]="['/admin/orders', order.id, 'detail']">
          View Order
        </a>
      </x-action-row>
    </xd-expand-item>
  }
</ng-container>
