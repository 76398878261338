<x-dashboard-form-layout [formGroup]="filterGroup">
  <mat-form-field>
    <mat-label>
      <fa-icon icon="store"></fa-icon>
      Channel
    </mat-label>
    <x-data-select
      formControlName="channelIds"
      [provider]="Providers.ChannelItemCollectionProvider"
      multiple
      [page]="{ index: 0, size: 100 }"
    ></x-data-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>
      <fa-icon icon="warehouse"></fa-icon>
      Warehouse
    </mat-label>
    <x-data-select
      formControlName="warehouseIds"
      [provider]="Providers.WarehouseItemCollectionProvider"
      multiple
      [page]="{ index: 0, size: 100 }"
    ></x-data-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="map-marked-alt"></fa-icon>
      Shipping Address Region
    </mat-label>
    <x-data-select
      formControlName="shippingAddressGeoRegionIds"
      [provider]="Providers.GeoRegionItemCollectionProvider"
      multiple
      [page]="{ index: 0, size: 100 }"
    ></x-data-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="truck"></fa-icon>
      Shipping Method
    </mat-label>
    <x-data-select
      formControlName="shippingMethodIds"
      [provider]="Providers.ShippingMethodItemCollectionProvider"
      multiple
      [page]="{ index: 0, size: 100 }"
    ></x-data-select>
  </mat-form-field>

  <ng-container formGroupName="shippingCategories">
    <mat-form-field>
      <mat-label>
        <fa-icon icon="layer-plus"></fa-icon>
        Shipping Category
      </mat-label>
      <x-data-autocomplete
        formControlName="ids"
        [provider]="Providers.ShippingCategoryItemCollectionProvider"
        [multiple]="true"
      >
        <x-pill *xDataAutocompleteTriggerDef="let option">
          {{ option.item.name }}
        </x-pill>
      </x-data-autocomplete>
      <mat-checkbox matSuffix formControlName="exclude">Exclude</mat-checkbox>
    </mat-form-field>
  </ng-container>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="tag"></fa-icon>
      Tag
    </mat-label>
    <x-data-select
      formControlName="tagIds"
      [provider]="Providers.OrderTagItemCollectionProvider"
      multiple
      [page]="{ index: 0, size: 100 }"
    ></x-data-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="stroopwafel"></fa-icon>
      Product
    </mat-label>
    <x-data-autocomplete
      formControlName="productVariantIds"
      [provider]="Providers.ProductVariantItemCollectionProvider"
      [multiple]="true"
    ></x-data-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="cart-flatbed"></fa-icon>
      Stock Item
    </mat-label>
    <x-data-autocomplete
      formControlName="stockItemIds"
      [provider]="Providers.StockItemItemCollectionProvider"
      [multiple]="true"
    ></x-data-autocomplete>
  </mat-form-field>

  <x-boolean-filter-field
    formControlName="isGift"
    icon="gift"
    label="Filter Gifts"
    trueLabel="Only Gifts"
    falseLabel="Exclude Gifts"
  ></x-boolean-filter-field>

  <!-- Checkout At Filter -->
  <x-date-range-filter-field
    icon="shopping-cart"
    label="Filter Delivery Date"
    formControlName="shippingSlotAt"
  ></x-date-range-filter-field>

  <mat-form-field>
    <mat-label>Relative Delivery Date</mat-label>
    <x-enum-select formControlName="relativeShippingSlotAt" enum="RelativeDateType"></x-enum-select>
  </mat-form-field>

  <!-- Shipping Slot Filter -->
  <x-date-range-filter-field
    icon="shopping-cart"
    label="Filter Checkout Date"
    formControlName="checkoutAt"
  ></x-date-range-filter-field>

  <mat-form-field>
    <mat-label>Relative Checkout Date</mat-label>
    <x-enum-select formControlName="relativeCheckoutAt" enum="RelativeDateType"></x-enum-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Order State</mat-label>
    <x-enum-select formControlName="states" enum="OrderState" multiple></x-enum-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Order Payment State</mat-label>
    <x-enum-select
      formControlName="paymentStates"
      enum="OrderPaymentState"
      multiple
    ></x-enum-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Payment Method</mat-label>
    <x-data-select
      formControlName="paymentMethodIds"
      [provider]="Providers.PaymentMethodItemCollectionProvider"
      multiple
      [page]="{ index: 0, size: 100 }"
    ></x-data-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Shipment State</mat-label>
    <x-enum-select formControlName="shipmentStates" enum="ShipmentState" multiple></x-enum-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="bullhorn"></fa-icon>
      Promotion
    </mat-label>
    <x-data-autocomplete
      formControlName="promotionIds"
      [provider]="Providers.PromotionItemCollectionProvider"
      [multiple]="true"
    ></x-data-autocomplete>
  </mat-form-field>

  <ng-container formGroupName="taxon">
    <mat-form-field>
      <mat-label>
        <fa-icon icon="layer-plus"></fa-icon>
        Taxon
      </mat-label>
      <x-data-autocomplete
        formControlName="ids"
        [provider]="Providers.TaxonItemCollectionProvider"
        [multiple]="true"
      >
        <x-taxon-pill
          *xDataAutocompleteTriggerDef="let option"
          [taxon]="option.item"
        ></x-taxon-pill>
      </x-data-autocomplete>
      <mat-checkbox matSuffix formControlName="exclude">Exclude</mat-checkbox>
    </mat-form-field>
  </ng-container>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="user"></fa-icon>
      Customer
    </mat-label>
    <x-data-autocomplete
      formControlName="userIds"
      [provider]="Providers.UserItemCollectionProvider"
      [multiple]="true"
    ></x-data-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="user"></fa-icon>
      Authored By
    </mat-label>
    <x-data-autocomplete
      formControlName="authorId"
      [provider]="Providers.UserItemCollectionProvider"
    ></x-data-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon></fa-icon>
      Order Source
    </mat-label>
    <x-data-select
      formControlName="orderSources"
      [provider]="Providers.OrderSourceItemCollectionProvider"
      multiple
      [page]="{ index: 0, size: 100 }"
    ></x-data-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <fa-icon icon="bullseye-pointer"></fa-icon>
      Collection Point
    </mat-label>
    <x-data-autocomplete
      formControlName="collectionPointIds"
      [provider]="Providers.CollectionPointItemCollectionProvider"
      [multiple]="true"
    ></x-data-autocomplete>
  </mat-form-field>
</x-dashboard-form-layout>
