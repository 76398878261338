import { NgModule } from '@angular/core';
import { LocaleModule } from '@x/common/locale';
import { MapModule } from '@x/common/map';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { LogisticsSharedModule } from '../logistics/logistics-shared.module';
import { SageSharedModule } from '../sage/sage-shared.module';
import { BulkOrderCreateDialogComponent } from './components/bulk-order-create-dialog/bulk-order-create-dialog.component';
import { BulkOrderTableComponent } from './components/bulk-order-table/bulk-order-table.component';
import { CartAdjustmentDialogComponent } from './components/cart-adjustment-dialog/cart-adjustment-dialog.component';
import { OrderCancelDialogComponent } from './components/order-cancel-dialog/order-cancel-dialog.component';
import { OrderCreateDialogComponent } from './components/order-create-dialog/order-create-dialog.component';
import { OrderCreateParcelDialogComponent } from './components/order-create-parcel-dialog/order-create-parcel-dialog.component';
import { OrderFiltersComponent } from './components/order-filters/order-filters.component';
import { OrderFulfilmentIntervalDialogComponent } from './components/order-fulfilment-interval-dialog/order-fulfilment-interval-dialog.component';
import { OrderGiftOptionsDialogComponent } from './components/order-gift-options-dialog/order-gift-options-dialog.component';
import { OrderIncidentCategoryTableComponent } from './components/order-incident-category-table/order-incident-category-table.component';
import { OrderIncidentInfoPanelComponent } from './components/order-incident-info-panel/order-incident-info-panel.component';
import { OrderIncidentOverlayComponent } from './components/order-incident-overlay/order-incident-overlay.component';
import { OrderIncidentTableComponent } from './components/order-incident-table/order-incident-table.component';
import { OrderInfoPanelComponent } from './components/order-info-panel/order-info-panel.component';
import { OrderInstructionsDialogComponent } from './components/order-instructions-dialog/order-instructions-dialog.component';
import { OrderItemManagerComponent } from './components/order-item-manager/order-item-manager.component';
import { OrderMapComponent } from './components/order-map/order-map.component';
import { OrderParcelFormDialogComponent } from './components/order-parcel-form-dialog/order-parcel-form-dialog.component';
import { OrderPaymentDialog2Component } from './components/order-payment-dialog/order-payment-dialog.component';
import { OrderRecreateDialogComponent } from './components/order-recreate-dialog/order-recreate-dialog.component';
import { OrderShippingFeeDialogComponent } from './components/order-shipping-fee-dialog/order-shipping-fee-dialog.component';
import { OrderTableComponent } from './components/order-table/order-table.component';
import { OrderTemplateFormDialogComponent } from './components/order-template-form-dialog/order-template-form-dialog.component';
import { OrderTotalsComponent } from './components/order-totals/order-totals.component';
import { CartsContextService } from './services/carts-context.service';
import { OrderActionsService } from './services/order-actions.service';
import { OrderDialogService } from './services/order-dialog.service';
import { OrderIncidentActionsService } from './services/order-incident-actions.service';

@NgModule({
  declarations: [
    OrderRecreateDialogComponent,
    OrderCreateDialogComponent,
    CartAdjustmentDialogComponent,
    OrderCreateParcelDialogComponent,
    OrderParcelFormDialogComponent,
    OrderGiftOptionsDialogComponent,
    OrderInstructionsDialogComponent,
    OrderFulfilmentIntervalDialogComponent,
    OrderPaymentDialog2Component,
    OrderCancelDialogComponent,
    OrderTableComponent,
    OrderFiltersComponent,
    OrderShippingFeeDialogComponent,
    BulkOrderTableComponent,
    BulkOrderCreateDialogComponent,
    OrderTemplateFormDialogComponent,
    OrderTotalsComponent,
    OrderMapComponent,
    OrderIncidentTableComponent,
    OrderIncidentCategoryTableComponent,
    OrderIncidentOverlayComponent,
    OrderItemManagerComponent,
    OrderInfoPanelComponent,
    OrderIncidentInfoPanelComponent,
  ],
  imports: [
    LocaleModule.forChild([]),
    EcommerceAdminCoreModule,
    SageSharedModule,
    MapModule,
    LogisticsSharedModule,
  ],
  providers: [
    OrderDialogService,
    CartsContextService,
    OrderActionsService,
    OrderIncidentActionsService,
  ],
  exports: [
    OrderCreateDialogComponent,
    CartAdjustmentDialogComponent,
    OrderParcelFormDialogComponent,
    OrderTableComponent,
    BulkOrderTableComponent,
    OrderFiltersComponent,
    OrderTotalsComponent,
    OrderMapComponent,
    OrderIncidentTableComponent,
    OrderIncidentCategoryTableComponent,
    OrderIncidentOverlayComponent,
    OrderItemManagerComponent,
    OrderInfoPanelComponent,
    OrderIncidentInfoPanelComponent,
  ],
})
export class OrdersSharedModule {}
