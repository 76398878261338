import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataView } from '@x/common/data';
import { Operation, OperationCancelledState } from '@x/common/operation';
import { ShipmentActionsService } from '@x/ecommerce-admin/app/logistics/services/shipment-actions.service';
import { ShipmentDialogService } from '@x/ecommerce-admin/app/logistics/services/shipment-dialog.service';
import {
  IOrderDetailObject,
  IOrderItemDetailObject,
  ShipmentObject,
  ShipmentService,
} from '@x/ecommerce/domain-client';
import { ShipmentStateTransition, ShipmentType } from '@x/schemas/ecommerce';
import { firstValueFrom, lastValueFrom, of, switchMap } from 'rxjs';
import { OrderActionsService } from '../../services/order-actions.service';
import { OrderDialogService } from '../../services/order-dialog.service';

export type InfoPanelDisplayMode = 'visible' | 'hidden';
export type ModifiableInfoPanelDisplayMode = 'readonly' | InfoPanelDisplayMode;

@Component({
  selector: 'x-order-info-panel',
  templateUrl: './order-info-panel.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OrderInfoPanelComponent implements OnInit, OnDestroy {
  @Input() view: DataView<IOrderDetailObject, number>;

  @Input() showChannel = true;
  @Input() showOrderMenu = true;
  @Input() showViewOrderButton = false;

  @Input() orderDetails: InfoPanelDisplayMode = 'visible';
  @Input() orderInfo: InfoPanelDisplayMode = 'hidden';
  @Input() orderItemsInfo: InfoPanelDisplayMode = 'hidden';
  @Input() customerInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() shippingAddressInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() couponReferrerInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() shippingSlotInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() giftOptionsInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() packingInstructionsInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() checkoutRequirementsInfo: InfoPanelDisplayMode = 'hidden';
  @Input() fulfilmentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() shipmentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() paymentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() promotionInfo: InfoPanelDisplayMode = 'hidden';
  @Input() stockInfo: InfoPanelDisplayMode = 'hidden';
  @Input() adjustmentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() voucherInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() subscriptionInfo: InfoPanelDisplayMode = 'hidden';
  @Input() sageInfo: InfoPanelDisplayMode = 'hidden';

  trackById = (i: number, item: { id: number }) => item.id;

  constructor(
    public readonly orderActions: OrderActionsService,
    private readonly snackbar: MatSnackBar,
    private readonly shipmentService: ShipmentService,
    private readonly shipmentActions: ShipmentActionsService,
    private readonly shipmentDialogService: ShipmentDialogService,
    private readonly orderDialogService: OrderDialogService,
  ) {}

  ngOnInit(): void {
    this.view.connect();
  }
  ngOnDestroy(): void {
    this.view.disconnect();
  }

  getTotalItemUnitCount(order: IOrderDetailObject) {
    return order.items.reduce(
      (n, i) =>
        n +
        i.shipmentUnits
          .filter((u) => u.shipment.type === ShipmentType.Delivery)
          .reduce((a, u) => a + u.quantity, 0),
      0,
    );
  }

  hasAdjustments(order: IOrderDetailObject) {
    return order.adjustments.length > 0 || order.items.some((i) => i.adjustments.length > 0);
  }

  getAdjustmentCount(order: IOrderDetailObject) {
    return order.adjustments.length + order.items.reduce((sum, i) => sum + i.adjustments.length, 0);
  }

  fulfilledQuantity(item: IOrderItemDetailObject) {
    return item.shipmentUnits
      .filter((u) => u.shipment.type === ShipmentType.Delivery)
      .reduce((a, b) => a + b.quantity, 0);
  }
  canBeFulfilled(item: IOrderItemDetailObject) {
    return this.fulfilledQuantity(item) >= item.quantity;
  }

  deliveryUnits(item: IOrderItemDetailObject) {
    return item.shipmentUnits.filter((u) => u.shipment.type === ShipmentType.Delivery);
  }

  reconShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.reconShipment({
          id,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Reconciled'));
  }
  submitShipmentWaybill(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Ready,
        }),
      )
      .subscribe((operation) =>
        this.handleOperationResult(operation, 'Shipment Waybill Submitted'),
      );
  }
  shipShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Ship,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Shipped'));
  }
  deliverShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Deliver,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Delivered'));
  }
  cancelShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Cancel,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Cancelled'));
  }
  failShipment(id: number) {
    this.view
      .observeMutation(() =>
        this.shipmentService.transitionShipment({
          id,
          sendNotification: false,
          transition: ShipmentStateTransition.Fail,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Failed'));
  }

  async createReturnShipment() {
    if (!this.view.id) return;

    const result = await firstValueFrom(
      this.shipmentDialogService
        .openReturnShipmentFormDialog({
          orderId: this.view.id,
          methodId: this.view.data?.deliveryShipment?.method?.id,
        })
        .afterClosed(),
    );
    console.log({ result });
    if (!result) return;

    this.view
      .observeMutation((id) =>
        this.shipmentService
          .createReturnShipment({
            orderId: result.orderId,
            methodId: result.methodId,
            slotId: result.slotId,
            units: result.units,
          })
          .pipe(
            switchMap((value) => {
              if (result.action === 'submit') {
                return this.shipmentService.initializeReturnShipment(value.id);
              }
              return of(value);
            }),
          ),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Return Shipment Created'));
  }

  async editReturnShipment(shipment: ShipmentObject) {
    if (!this.view.data) return;
    this.view
      .observeMutation(() => this.shipmentActions.editReturnShipment(shipment))
      .subscribe((operation) => this.handleOperationResult(operation, 'Return Shipment Updated'));
  }

  async rescheduleShipment(shipmentId: number) {
    const result = await lastValueFrom(
      this.shipmentDialogService
        .openShipmentFormDialog({
          shipmentId,
        })
        .afterClosed(),
    );

    if (!result) return;

    const { methodId, slotId } = result;

    this.view
      .observeSubMutation(shipmentId, (id) =>
        this.shipmentService.rescheduleShipment({
          id: shipmentId,
          methodId,
          slotId,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Rescheduled'));
  }
  async recreateShipment(shipmentId: number) {
    const result = await lastValueFrom(
      this.shipmentDialogService
        .openShipmentFormDialog({
          shipmentId,
        })
        .afterClosed(),
    );

    if (!result) return;

    const { methodId, slotId } = result;

    this.view
      .observeSubMutation(shipmentId, (id) =>
        this.shipmentService.recreateShipment({
          id: shipmentId,
          methodId,
          slotId,
        }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment Recreated'));
  }
  async openParcelDialog(shipmentId: number) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderCreateParcelDialog().afterClosed(),
    );

    if (!result) return;

    this.view
      .observeSubMutation(shipmentId, (id) =>
        this.shipmentService.createShipmentParcel({ shipmentId: Number(id), ...result }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel created'));
  }
  async removeParcel(parcel: any) {
    const result = await firstValueFrom(
      this.orderDialogService.openParcelRemoveConfirmation(parcel),
    );

    if (!result) return;

    this.view
      .observeMutation(() => this.shipmentService.removeParcel(parcel.id))
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel removed'));
  }
  async editParcel(parcel: any) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderUpdateParcelDialog(parcel).afterClosed(),
    );
    if (!result) return;

    this.view
      .observeMutation(() => this.shipmentService.updateParcel({ id: parcel.id, ...result }))
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel updated'));
  }

  private handleOperationResult(operation: Operation, successMessage?: string) {
    if (operation instanceof OperationCancelledState) {
      return;
    }
    if (operation.isSuccessState() && successMessage) {
      this.snackbar.open(successMessage);
    }
    if (operation.isErrorState()) {
      this.snackbar.open(operation.state.error.message);
    }
  }
}
